@charset "utf-8";

// Colors
$red: #820000;
$red-light: #8c1a15;
$white: #ffffff;
$blue-dark: #000063;
$blue-dark-2: #00004b;
$blue: #009fe3;

// Fonts
$regular: "flamablackregular", sans-serif;
$black-italic: "flamablackitalic", sans-serif;
$italic: "flamabookitalic", sans-serif;
$light-italic: "flamalightitalic", sans-serif;

// Vars
$gap: 32px;

@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/grid/_all.sass";
@import "../node_modules/bulma/sass/elements/container.sass";

@import "../node_modules/sass-rem/rem";

@font-face {
  font-family: "flamablackitalic";
  src: url("assets/fonts/flamablack_italic-webfont.woff2") format("woff2"),
    url("assets/fonts/flamablack_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "flamablackregular";
  src: url("assets/fonts/flamablack_regular-webfont.woff2") format("woff2"),
    url("assets/fonts/flamablack_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "flamabookitalic";
  src: url("assets/fonts/flamabook_italic-webfont.woff2") format("woff2"),
    url("assets/fonts/flamabook_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "flamalightitalic";
  src: url("assets/fonts/flamalight_italic-webfont.woff2") format("woff2"),
    url("assets/fonts/flamalight_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: "flamabasic";
//   src: url("assets/fonts/flama_basic-webfont.woff2") format("woff2"),
//     url("assets/fonts/flama_basic-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "flamabold";
//   src: url("assets/fonts/flama_bold-webfont.woff2") format("woff2"),
//     url("assets/fonts/flama_bold-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

html {
  font-size: 100%;

  @include mobile {
    font-size: 60%;
  }

  @include tablet {
    font-size: 70%;
  }

  @include desktop {
    @include rem-baseline(80%);
  }
}

body {
  overflow-x: hidden;
  background-image: url(./assets/img/bg.jpg);
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-color: $red;
  background-size: 100vw auto;
  width: 100vw;
  min-height: 100vh;
}

header {
  max-width: none;
  width: auto;
  padding-top: 80px;
  display: flex;
  margin-bottom: 100px;

  @media screen and (max-width: 375px) {
    .laptop {
      transform: translate(30px, -30px);
    }
  }

  .logo {
    text-align: right;
    transform: translate(-15%, 120px);
    font-family: $light-italic;
    font-size: rem(52px);
    line-height: 48px;
    letter-spacing: 5.2px;
    color: $white;

    h1 {
      margin: 50px 0;
    }

    h2 {
      span {
        display: block;
        font-family: $black-italic;
        color: $blue;
      }
    }
  }
}

.container {
  margin-left: $gap !important;
  margin-right: $gap !important;
}

.underline-hover {
  text-decoration: none;
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-size: 0% 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s;

  &:hover {
    background-size: 100% 100%;
  }
}

.info {
  padding-top: 2 * $gap;
  padding-bottom: 2 * $gap;
  .columns {
    justify-content: space-around;
    .column {
      max-width: 800px;
    }

    @include mobile {
      display: block;
    }
  }

  &__content {
    font-family: $italic;
    font-size: rem(36px);
    line-height: 59px;
    letter-spacing: -0.18px;
    color: $white;

    p {
      margin-bottom: $gap;
      span.bold,
      span.bold-blue {
        font-family: $black-italic;
        font-size: rem(36px);
        line-height: 59px;
      }

      span.bold-blue {
        color: $blue;
      }
    }
  }
}

.courses {
  h2 {
    position: relative;
    z-index: 1;
    font-family: $light-italic;
    font-style: italic;
    font-size: rem(52px);
    line-height: 48px;
    letter-spacing: -0.26px;
    color: $white;
    display: inline-block;
    padding: 10px 20px 10px 10px;
    margin-bottom: 20px;

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $blue;
      transform: skewX(-15deg);
    }
  }

  hr {
    margin: $gap 0;

    border-bottom: 3px dotted $blue;
    background: none;
  }

  ul {
    padding-left: 30px;
    li {
      position: relative;
      margin-bottom: $gap;

      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        background-color: $blue;
        transform: skewX(-15deg);
        left: -40px;
        top: 14px;
      }

      a {
        font-family: $black-italic;
        font-size: rem(46px);
        line-height: 44px;
        letter-spacing: -0.23px;
        color: $white;
      }

      &.eng-info a {
        $fs: 73px;
        font-style: italic;
        font-size: rem($fs);
        line-height: 63px;
        letter-spacing: -0.36px;
        @include mobile {
          font-size: rem($fs * 0.8);
        }
      }
    }
  }
}

.subscription {
  $gap-top-bottom: $gap * 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $gap-top-bottom $gap;
  background-color: $blue-dark;
  font-family: $regular;
  $fs: 49px;
  font-size: rem($fs);

  @include mobile {
    font-size: rem($fs * 0.6);
    padding: $gap-top-bottom $gap * 0.5;
  }

  a {
    color: #ffffff;
  }
}

.offer {
  padding: $gap;
  @include mobile {
    padding: $gap * 0.5;
  }

  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 360px;
  background-color: $blue;
  background-image: url(assets/img/offerg.jpg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;

  .offer__content {
    margin-right: 10%;
    h2,
    h3,
    h4 {
      font-family: $black-italic;
      font-size: rem(64px);
      line-height: 1;
      letter-spacing: -0.37px;
      color: $red-light;
    }

    $padding-left: $gap;

    h3 {
      color: $white;
      padding-left: $padding-left;
    }

    h4 {
      color: $blue-dark-2;
      font-size: rem(48px);
      line-height: 1;
      letter-spacing: -0.19px;
      padding-left: $padding-left;
    }
  }
}

footer {
  padding: $gap;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include touch {
    flex-direction: column-reverse;
    a {
      margin-top: $gap;
    }
  }

  a {
    font-family: $italic;
    font-size: rem(41px);
    line-height: 36px;
    color: $white;
    &:hover {
      color: $white;
    }
  }
}
